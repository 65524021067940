import axios from 'axios';
import CryptoJS from 'crypto-js';

export default class facebookConversionsAPI {

    constructor(){
        
        this.metaPixelId = import.meta.env.VITE_META_PIXEL_ID;
        this.metaAccessToken = import.meta.env.VITE_META_CONVRSIONS_API_ACCESS_TOKEN; 
        this.currentTimeInSeconds = Math.floor(Date.now() / 1000);
        this.eventSourceUrl = window.location.href;
        // this.testEventCode = 'TEST75907';

    }

    // 檢查是否有 meta Pixel Id & Access Token
    checkIsMetaIdAndToken() {
        let MetaIdAndTokenExist = false;
        if (this.metaPixelId && this.metaAccessToken) {
            MetaIdAndTokenExist = true;
        }
        return MetaIdAndTokenExist;
    }

    // 瀏覽商品時
    sendViewContent(userData, viewContentData) {
        const { client_user_agent, client_ip_address, email, name, city, country, phone, birthday, gender } = userData;
        axios.post(`https://graph.facebook.com/v19.0/${this.metaPixelId}/events`, {
            data: [
                {
                    event_name: "ViewContent",
                    event_time: this.currentTimeInSeconds,
                    event_source_url: this.eventSourceUrl,
                    event_id: "event.id.ViewContent",
                    action_source: "website" ,
                    user_data: {
                        "client_user_agent": client_user_agent,
                        "client_ip_address": client_ip_address,
                        "em": hashValue(email), // Email
                        "fn": hashValue(name), // Name
                        "ct": hashValue(city), // City
                        "country": hashValue(country), // country
                        "ph": hashValue(phone), // Phone
                        "db": hashValue(birthday), // Birth
                        "ge": hashValue(gender), // Gender
                    },
                    custom_data: {
                        "contents": viewContentData.contents.map(product => ({
                            "id": product.id,
                            "item_price": product.item_price,
                        })),
                        "currency": viewContentProductsData.currency,
                    }
                }
            ],
            // test_event_code: this.testEventCode,
            access_token: this.metaAccessToken
        });
    }

    // 加入購物車時
    sendAddToCart(userData, addToCartData) {
        const { client_user_agent, client_ip_address, email, name, city, country, phone, birthday, gender } = userData;
        return axios.post(`https://graph.facebook.com/v19.0/${this.metaPixelId}/events`, {
            data: [
                {
                    event_name: "AddToCart",
                    event_time: this.currentTimeInSeconds,
                    event_source_url: this.eventSourceUrl,
                    event_id: "event.id.AddToCart",
                    action_source: "website" ,
                    user_data: {
                        "client_user_agent": client_user_agent,
                        "client_ip_address": client_ip_address,
                        "em": hashValue(email), // Email
                        "fn": hashValue(name), // Name
                        "ct": hashValue(city), // City
                        "country": hashValue(country), // country
                        "ph": hashValue(phone), // Phone
                        "db": hashValue(birthday), // Birth
                        "ge": hashValue(gender), // Gender
                    },
                    custom_data: {
                        "contents": addToCartProductData.contents.map(product => ({
                            "id": product.id,
                            "quantity": product.quantity,
                            "item_price": product.item_price,
                        })),
                        "currency": addToCartProductData.currency,
                    }
                }
            ],
            // test_event_code: this.testEventCode,
            access_token: this.metaAccessToken
        });
    }

    // 新增訂購人資料時
    sendAddPaymentInfo(paymentUserData) {
        // const { em, fn, ph } = paymentUserData;
        axios.post(`https://graph.facebook.com/v19.0/${this.metaPixelId}/events`, {
            data: [
                {
                    event_name: "AddPaymentInfo",
                    event_time: this.currentTimeInSeconds,
                    event_source_url: this.eventSourceUrl,
                    event_id: "event.id.AddPaymentInfo",
                    action_source: "website" ,
                    user_data: {
                        "em": hashValue(paymentUserData.em), // Email
                        "fn": hashValue(paymentUserData.fn), // Name
                        "ph": hashValue(paymentUserData.ph), // Phone
                    },
                }
            ],
            // test_event_code: this.testEventCode,
            access_token: this.metaAccessToken
        });
    }

    // 點結帳時獲取訂購人資料 & 購物車內容
    sendInitiateCheckout(initiateCheckoutData) {
        axios.post(`https://graph.facebook.com/v19.0/${this.metaPixelId}/events`, {
            data: [
                {
                    event_name: "InitiateCheckout",
                    event_time: this.currentTimeInSeconds,
                    event_source_url: this.eventSourceUrl,
                    event_id: "event.id.InitiateCheckout",
                    action_source: "website" ,
                    user_data: {
                        "em": hashValue(initiateCheckoutData.em), // Email
                        "fn": hashValue(initiateCheckoutData.fn), // Name
                        "ph": hashValue(initiateCheckoutData.ph), // Phone
                    },
                    custom_data: {
                        "contents": initiateCheckoutData.contents.map(product => ({
                            "id": product.id,
                            "quantity": product.quantity,
                            "item_price": product.item_price,
                        })),
                        "currency": initiateCheckoutData.currency,
                        "delivery_category": initiateCheckoutData.delivery_category,
                    },
                }
            ],
            // test_event_code: this.testEventCode,
            access_token: this.metaAccessToken
        });
    }

    // 結帳/訂單成立後
    sendPurchase(userData, purchaseData) {
        const { client_user_agent, client_ip_address, email, name, city, country, phone, birthday, gender } = userData;
        axios.post(`https://graph.facebook.com/v19.0/${this.metaPixelId}/events`, {
            data: [
                {
                    event_name: "Purchase",
                    event_time: this.currentTimeInSeconds,
                    event_source_url: this.eventSourceUrl,
                    event_id: "event.id.Purchase",
                    action_source: "website" ,
                    user_data: {
                        "client_user_agent": client_user_agent,
                        "client_ip_address": client_ip_address,
                        "em": hashValue(email), // Email
                        "fn": hashValue(name), // Name
                        "ct": hashValue(city), // City
                        "country": hashValue(country), // country
                        "ph": hashValue(phone), // Phone
                        "db": hashValue(birthday), // Birth
                        "ge": hashValue(gender), // Gender
                    },
                    custom_data: {
                        "contents": purchaseData.contents.map(product => ({
                            "id": product.id,
                            "quantity": product.quantity,
                            "item_price": product.item_price,
                        })),
                        "value": purchaseData.value,
                        "currency": purchaseData.currency,
                        "delivery_category": purchaseData.delivery_category,
                    }
                }
            ],
            // test_event_code: this.testEventCode,
            access_token: this.metaAccessToken
        });
    }

    // 雜湊 
    hashValue(value) {
        return CryptoJS.SHA256(value).toString();
    };

};

